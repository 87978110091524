import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/design-system/atoms/Button';
import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import { trackInteraction } from '@/utils/analytics';

import styles from './Card.module.scss';

const CardContext = React.createContext();
const CardContextProvider = (props) => {
    const { children, initialValue = {} } = props;

    const [state, setState] = React.useState(initialValue);

    return <CardContext.Provider value={{ state, setState }}>{children}</CardContext.Provider>;
};
export const useCardContext = () => React.useContext(CardContext);

export const CardRoot = (props) => {
    const { children, isClickableCard = false } = props;

    return <CardContextProvider initialValue={{ isClickableCard }}>{children}</CardContextProvider>;
};

CardRoot.propTypes = {
    isClickableCard: PropTypes.bool,
};

export const CardContainer = React.forwardRef((props, ref) => {
    const {
        children,
        className,
        ariaLabel,
        href,
        csr,
        onClick,
        onKeyDown,
        onFocus,
        onBlur,
        onMouseEnter,
        onMouseLeave,
        analyticsActionLabel,
        analyticsComponentTitle,
        ...otherProps
    } = props;

    const { state } = useCardContext();

    const [dataState, setDataState] = React.useState({
        hover: undefined,
        focus: undefined,
    });

    const [Component, isClickable, isCsr] = React.useMemo(() => {
        if (!state.isClickableCard) {
            return ['div', false];
        }

        const cta = state.callToAction ?? {};

        const isLink = cta.href || href;
        const isButton = cta.onClick || cta.onKeyDown || onClick || onKeyDown;

        const isClickable = isLink || isButton;
        const isCsr = isLink && (cta.csr || csr);

        let Component = 'div';

        if (isLink) {
            Component = csr || cta.csr ? Link : 'a';
        } else if (isButton) {
            Component = 'button';
        }

        return [Component, isClickable, isCsr];
    }, [href, csr, onClick, onKeyDown, state.callToAction, state.isClickableCard]);

    return (
        <Component
            ref={ref}
            className={classnames(
                styles['container'],
                isClickable && styles['container--clickable'],
                className,
            )}
            {...otherProps}
            href={isClickable ? href ?? state.callToAction?.href : href}
            to={isCsr ? href ?? state.callToAction?.href : undefined}
            onClick={(e) => {
                onClick?.(e);
                isClickable && state.callToAction?.onClick?.(e);
                isClickable &&
                    (analyticsActionLabel || analyticsComponentTitle) &&
                    trackInteraction({
                        componentName: 'card',
                        selector: 'card',
                        componentTitle: analyticsComponentTitle,
                        actionLabel: analyticsActionLabel,
                        linkHref: href ?? state.callToAction?.href,
                        interactionType: 'cta',
                    });
            }}
            onKeyDown={(e) => {
                onKeyDown?.(e);
                isClickable && state.callToAction?.onKeyDown?.(e);
            }}
            onFocus={(e) => {
                isClickable && setDataState((s) => ({ ...s, focus: true }));
                onFocus?.(e);
            }}
            onBlur={(e) => {
                isClickable && setDataState((s) => ({ ...s, focus: undefined }));
                onBlur?.(e);
            }}
            onMouseEnter={(e) => {
                isClickable && setDataState((s) => ({ ...s, hover: true }));
                onMouseEnter?.(e);
            }}
            onMouseLeave={(e) => {
                isClickable && setDataState((s) => ({ ...s, hover: undefined }));
                onMouseLeave?.(e);
            }}
            aria-label={isClickable ? ariaLabel ?? state.callToAction?.ariaLabel : ariaLabel}
            data-state-hover={dataState.hover}
            data-state-focus={dataState.focus}
            data-trigger={
                isClickable && (analyticsActionLabel || analyticsComponentTitle)
                    ? 'card'
                    : undefined
            }
        >
            {children}
        </Component>
    );
});

CardContainer.displayName = 'CardContainer';

CardContainer.propTypes = {
    /**
     * Card Container `aria-label`
     */
    ariaLabel: PropTypes.string,
    /**
     * `href` prop for link clickable card
     */
    href: PropTypes.string,
    /**
     * `csr` prop for whether it is client side rendering
     */
    csr: PropTypes.bool,
    /**
     * `onClick` prop for link/button clickable card
     */
    onClick: PropTypes.func,
    /**
     * `onKeyDown` prop for link/button clickable card
     */
    onKeyDown: PropTypes.func,
    /**
     * Card analytics tracking: Button/link value
     */
    analyticsActionLabel: PropTypes.string,
    /**
     * Card analytics tracking: Component Title
     */
    analyticsComponentTitle: PropTypes.string,
};

export const CardHeader = React.forwardRef((props, ref) => {
    const { children, className, ...otherProps } = props;

    return (
        <div ref={ref} className={classnames(styles['header'], className)} {...otherProps}>
            {children}
        </div>
    );
});

CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
    children: PropTypes.node,
};

export const CardContent = React.forwardRef((props, ref) => {
    const { children, className, ...otherProps } = props;

    return (
        <div ref={ref} className={classnames(styles['content'], className)} {...otherProps}>
            {children}
        </div>
    );
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
    children: PropTypes.node,
};

export const CardCallToAction = React.forwardRef((props, ref) => {
    const {
        wrapperProps = {},
        className,
        href,
        csr,
        onClick,
        onKeyDown,
        iconComponent,
        ariaLabel,
        analyticsActionLabel,
        analyticsComponentTitle,
        ...otherProps
    } = props;
    const { className: wrapperClassName, ...otherWrapperProps } = wrapperProps;

    const { state, setState } = useCardContext();

    const isIconOnly = !props.label;

    React.useEffect(() => {
        setState((s) => ({
            ...s,
            callToAction: props,
        }));
    }, [setState, props]);

    return (
        <div
            className={classnames(
                styles['call-to-action'],
                isIconOnly && styles['call-to-action--icon-only'],
                wrapperClassName,
            )}
            {...otherWrapperProps}
        >
            <Button
                ref={ref}
                component={state.isClickableCard ? 'span' : undefined}
                role={state.isClickableCard ? 'presentation' : undefined}
                ariaLabel={state.isClickableCard ? undefined : ariaLabel}
                aria-hidden={state.isClickableCard ? 'true' : undefined}
                href={state.isClickableCard ? undefined : href}
                csr={state.isClickableCard ? undefined : csr}
                onClick={state.isClickableCard ? undefined : onClick}
                onKeyDown={state.isClickableCard ? undefined : onKeyDown}
                iconComponent={isIconOnly ? iconComponent ?? LazyIconArrowRightCircle : undefined}
                buttonStyle={isIconOnly ? 'tertiary' : 'text'}
                className={classnames(
                    styles['call-to-action__button'],
                    isIconOnly && styles['call-to-action__button--icon-only'],
                    className,
                )}
                analytics={
                    state.isClickableCard
                        ? undefined
                        : {
                              componentName: 'card',
                              selector: 'card',
                              interactionType: 'cta',
                              componentTitle: analyticsComponentTitle,
                              actionLabel: analyticsActionLabel,
                          }
                }
                {...otherProps}
            />
        </div>
    );
});

CardCallToAction.displayName = 'CardCallToAction';

CardCallToAction.propTypes = {
    wrapperProps: PropTypes.object,
    ...Button.propTypes,
};

export const Card = {
    Root: CardRoot,
    Container: CardContainer,
    Header: CardHeader,
    Content: CardContent,
    CallToAction: CardCallToAction,
};
